.TransportFootprint {
    border: 1px solid #ddd;
    border-radius: 0;
    background: #FDFDFD;
    border-left: 2px solid var(--ac-color-primary);
    padding: 8px;
    margin-bottom: 8px;

    &__header {
        display :flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h4, h5 {
            margin-top: 0;
            margin-bottom: 0;
        }

        .af-form-button {
            font-size: 14px;
            padding: 6px;
        }
    }


    .af-form-button {
        margin-top: 0;
        margin-left: auto;
        display: block;
    }

    ~ .af-form-button {
        margin-top: 10px;
    }
}
