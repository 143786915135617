
.af-field-container.af-field-container--ProductField {
  display: table;
  width: 100%;
}

.af-field-container.af-field-container--ProductField .af-field {
  display: table-cell;
  width: 24px;
}

.af-field-container.af-field-container--ProductField .af-label {
  display: table-cell;
  width: calc(100% - 24px);
}

.af-field-container--ProductsField .Products__Product.summary .name {
  padding-left: 0 !important;
}