.Summary {
    text-align: left;

    .af-field-container--WorkshopRegistration {

        label {
            line-height: 1;
        }

        >.list {
            padding-left: 0px;
        }

    }

    .af-field-container {
        display: flex;

        .af-label {
            text-align: left;
            min-width: fit-content !important;
        }
    }
}