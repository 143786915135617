.table--workshop-quota .col--allocated {
  width: 130px;
  text-align: left;
}

.table--workshop-quota tr {
  cursor: pointer;
}

.table--workshop-quota .col--remaining {
  width: 130px;
  text-align: right;
}

.af-button--small {
  cursor: pointer;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}