.af-container {
  display: flex;
  justify-content: center;
}

.af-field-container {
  display: table;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  .af-label {
    display: table-cell;
    font-weight: bold;
    padding-right: 15px;
    vertical-align: top;
    min-width: 175px;
    text-align: right;
  }

  .af-field {
    display: table-cell;
    width: 100%;
  }

  .af-error {
    margin: 0px;
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }

  .af-description {
    font-size: 0.85em;
    color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 481px) {
  .af-field-container.is-vertical {
    display: block;

    .af-label {
      display: block;
      text-align: left;
    }

    .af-field {
      display: block;
      padding-left: 175px;
      width: initial;
    }
  }

  .af-field-container--RadioField .af-field label {
    padding: 4px 0;
  }
}

@media (max-width: 480px) {
  .af-field-container {
    display: block;
  }

  .af-field-container--RadioField div {
    display: block !important;
  }

  .af-field-container--RadioField label {
    display: block !important;
    padding-top: 0px !important;
    margin-right: 0 !important;
  }

  .af-field-container .af-label {
    min-width: auto !important;
    width: auto !important;
    padding: 0px !important;
    display: block;
    text-align: left;
    margin-bottom: 5px;
  }

  .af-field-container .af-field {
    display: block;
  }

  .af-field-container.is-vertical input.af-radio-field {
    margin: 0 8px -3px 0;
  }

  .af-field-container--RadioField .af-field label {
    padding: 8px 0;
  }
}
