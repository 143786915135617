.af-field-container.af-field-container--RadioField {
  &.is-disabled {
    opacity: 0.65;

    label {
      cursor: not-allowed;
    }
  }

  label {
    line-height: 21px;

    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.35;

      input {
        cursor: inherit;
      }
    }

    &:not(.is-disabled) {
      cursor: pointer;
    }
  }

  .af-field {
    .af-radio-label {
      margin-right: 20px;
    }
  }

  .af-radios {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .af-checkbox-field {
      margin: 0 8px 0 0;
    }
  }

  .af-radio-label {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &.is-disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  &.is-vertical {
    .af-radios {
      display: block;
    }

    .af-radio-label {
      display: block;
    }
  }

  &.af-field-container--RadioField--button,
  &.af-field-container--RadioField--product {
    > .af-label {
      padding-top: 0.5em;
    }

    > .af-field {
      padding-left: 0 !important;
      margin-top: 8px;
    }

    > .af-field > div {
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .af-radio-image {
      width: 60px;
      height: 60px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 4px;
        overflow: hidden;
        max-width: 100%;
      }
    }

    > .af-field .af-radio-label {
      padding: 8px 12px !important;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin-bottom: 8px;
      margin-right: 0px !important;
      flex-grow: 0;
      box-sizing: border-box;

      &.is-disabled {
        background: rgba(0, 0, 0, 0.05);
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }

    > .af-field .af-radio-label {
      width: calc(50% - 4px);
    }

    &.is-button-3 > .af-field > div > label {
      width: calc(33% - 4px);
    }
  }

  &.af-field-container--RadioField--product {
    > .af-field > .af-radios > .af-radio-label {
      width: calc(100% - 4px);
    }
  }

  .description {
    opacity: 0.8;
    font-size: 0.85em;
  }

  .text--workshops--room-left {
    opacity: 0.6;
    font-size: 0.85em;
    padding-left: 8px;
  }
}
