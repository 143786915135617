.af-card {
    font-size: 0.95em;
    padding: 1em;
    border-radius: 4px;
    margin-bottom: 1em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    >h1:first-child,
    >h2:first-child,
    >h3:first-child {
        margin-top: 0;
    }

    >h1:last-child,
    >h2:last-child,
    >h3:last-child {
        margin-bottom: 0;
    }

    .af-form-button {
        margin-right: 0.5em;
        box-shadow: none !important;
    }

    .af-field-container--RadioField {
        label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 20px;
        }
    }

    &__actions {
        text-align: right;

        .af-form-button {
            font-size: 13px;
            display: inline-block;
        }
    }
}

.af-form-button {
    &.af-form-button {


        &--success {
            color: #2d7317 !important;
            background-color: #bbefb9 !important;
        }

        &--error {
            color: #c93b33 !important;
            background-color: #ffb2ad !important;
        }
    }
}