body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', Oxygen, Cantarell, sans-serif;
  background-color: #eee;
  overflow-wrap: anywhere;
  padding: 20px;
}

// On chrome by default a textarea is in monospace...
textarea {
  font-family: inherit;
}

.af-container > div {
  max-width: 100%;
}

.af-container__background {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-form-container {
  width: 700px;
  box-sizing: border-box;
  border-radius: 3px;
  max-width: 100%;
  padding: 30px;
  color: var(--ac-color-text);

  &--paper {
    box-shadow: 2px 2px 5px 1px rgba(151, 144, 138, 0.6);
    background-color: var(--ac-color-bg);
  }
  &--basic {
    background-color: transparent;
    box-shadow: none;
  }
  &--border {
    .af-form-fields-container {
      border: 2px var(--ac-color-primary) solid;
    }
  }
  &--width {
    &-normal {
      width: 700px;
    }
    &-large {
      width: 1127px;
    }
    &-fullWidth {
      width: 100%;
    }
  }
}

.af-form-header img {
  width: 100%;
  display: block;
}

// .af-form-container {

// }

.af-form-fields-container {
  padding: 30px;
}

@media (max-width: 480px) {
  .af-form-container {
    margin: 0px !important;
    padding: 8px;
    box-shadow: none;
    width: 100%;
  }

  .af-form-fields-container {
    padding: 15px;
  }
}

/* If we have a header, remove the top border */
.af-form-fields-container:not(:first-child) {
  border-top-width: 0;
}

.af-checkbox-field {
  margin-top: 0;
  margin-bottom: 4px;
  vertical-align: middle;
}

.af-radio-field {
  background: #ddd;
  margin-left: 0px;
}

.af-radio-field:checked {
  background: #777;
}

/*** confirm button ***/

.af-form-button {
  text-transform: uppercase;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 15px;
  font-weight: bold;
  padding: 7px 15px 7px 15px;
  border-radius: 3px;
  margin-top: 20px;
  &--incorrect {
    background-color: lightcoral !important;
  }
}

a.af-form-button {
  text-decoration: none;
}

.af-separator {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 0;
  border-bottom: 1px solid rgba(127, 127, 127, 0.25);
}

/****** Field styles ***/

input.af-string-field,
input.af-number-field,
input.af-email-field,
input.af-password-field {
  width: 100%;
  flex: 1;
  border: none;
  outline: none;
  box-shadow: none;
  box-shadow: inset 0 -1px 0 0 rgba(127, 127, 127, 0.25);
  padding-bottom: 3px;
  position: relative;
  background-color: transparent;
  font-size: 15px;
  /* color: #FFF; */
}

.af-string-field {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &__incorrect-hint {
    color: lightcoral;
    font-size: 12px;
  }
  &--incorrect {
    background-color: lightcoral !important;
  }
}

input.af-password-field {
  margin-bottom: 8px;
}

.af-multi-string-container {
  display: flex;
  flex-direction: row;
  width: calc(100% + 12px);
}

.af-multi-select-container > div {
  width: 100%;
}

input.af-multi-string-field {
  width: 100px;
  flex: 1;
  margin-right: 12px;
  border: none;
  outline: none;
  box-shadow: none;
  box-shadow: inset 0 -1px 0 0 rgba(127, 127, 127, 0.25);
  padding-bottom: 3px;
  position: relative;
  background-color: transparent;
  font-size: 15px;
  /* color: #FFF; */
}

.af-dateselect-field {
  padding: 5px;
  margin-right: 8px;
  position: relative;
}

.af-field-container--DateSelectField .af-label {
  padding-top: 6px;
}

.af-dateselect-field {
  display: inline-block;
  font-size: 15px;
  /* font-weight: 700; */
  color: #444;
  line-height: 1.3;
  padding: 0.3em 1.7em 0.25em 0.8em;
  /* width: 100%;
  max-width: 100%; */
  box-sizing: border-box;
  margin: 0;
  margin-right: 4px;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.af-dateselect-field::-ms-expand {
  display: none;
}

.af-dateselect-field:hover {
  border-color: #888;
}

.af-dateselect-field:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.af-dateselect-field option {
  font-weight: normal;
}

/* ::placeholder {
  color: #ddd;
} */

.af-field-container--TextBlock {
  margin-top: 12px;
  margin-bottom: 12px;
}

.af-radio-field {
  height: 18px;
  width: 18px;
  margin-top: 0px;
  margin-right: 8px;
  transition: all 0.15s ease-out 0s;
  border: none;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  appearance: none;
  border-radius: 50%;
  box-shadow: none;
  flex-shrink: 0;
}

.af-radio-field:checked::before {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 5px;
  left: 3px;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 10px;
}

textarea.af-textarea-field {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px rgba(127, 127, 127, 0.25);
  outline: none;
  border: none;
  width: 95%;
  padding: 10px;
  font-size: 14px;
  /* color: white; */
}

.af-field-container.af-field-container--Image {
  display: block;
  margin-bottom: 20px;
}

.af-field-container.af-field-container--Image > img {
  display: block;
  margin: 0 auto;
}

.af-field-container--WorkshopRegistration label {
  padding: 8px;
  // line-height: 32px;
  cursor: pointer;
}

.af-field-container--WorkshopRegistration label input {
  margin-right: 16px;
}

.af-field-container--WorkshopRegistration .text--workshops--room-left {
  opacity: 0.6;
  font-size: 0.9em;
  padding-left: 8px;
}

.af-columns {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.af-column {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}

.af-column--1_3 {
  display: table-cell;
  vertical-align: top;
  width: 33%;
}

.af-column img {
  max-width: 100%;
}

.af-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.25s ease-in-out;
}

.af-arrow--right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.af-arrow--down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.af-field-container.is-disabled {
  opacity: 0.6;
}

.af-message {
  margin-top: 1em;
}

.af-message--alert {
  color: #e4002b;
}

/* .af-field-container.is-required .af-label:after {
  content: " *";
  color: red;
} */

@media (max-width: 480px) {
  .af-columns {
    display: block;
    width: 100%;
  }

  .af-column {
    display: block;
    width: 100%;
  }

  .af-column--1_3 {
    display: block;
    width: 100%;
  }
}

.af-field-container--ActivityRegistration {
  padding: 16px;
}

.af-field-container.af-field-container--AccommodationReservation.is-vertical .af-field,
.af-field-container--TextareaField.is-vertical .af-field {
  padding-left: 0 !important;
}

.af-field-container--ListItem {
  padding: 16px;
}

.af-team__accordion {
  display: table;
  padding: 8px;
  border: 1px solid #000;
  width: calc(100% - 16px);
  margin: 4px 0px;

  &.incomplete {
    border-color: #d30f00;
    color: #d30f00;
    background-color: #fff6f6;
  }
}

.af-tabs {
  .af-message--error.required-fields {
    display: none;
  }
}

.af-team__header {
  display: flex;
  justify-content: center;
  align-items: center;

  span.incomplete {
    background-color: #d30f00;
    color: white;
    border-radius: 12px;
    margin: 0px 6px;
    padding: 4px 8px;
    font-size: 12px;
  }
}

.af-team__button {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  touch-action: manipulation;
  outline: none;
  cursor: pointer;
  border: none;
  font-size: 15px;
  padding: 7px 15px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-weight: bold;
}

.af-activity-entry {
  border: 1px solid #e22345;
  margin: 8px;
  padding: 8px;
  width: calc(100% - 16px);
  box-sizing: border-box;
  position: relative;
}

.af-activity-entry__title {
  font-size: 1.2em;
  cursor: pointer;
  padding-right: 1em;
}

.af-activity-entry__title .checkbox--room-left {
  font-size: 0.8em;
  font-weight: 100;
}

.af-activity-entry__title .fa {
  font-size: 0.9em;
  position: absolute;
  top: 0.7em;
  right: 0.5em;
  opacity: 0.75;
}

.af-activity-entry__description {
  font-style: italic;
  margin: 16px 0;
  font-size: 1.1em;
}

.af-activity-entry__input {
  background: transparent;
  /* color: #E22345; */
  border: 1px solid #e22345;
  font-size: 0.9em;
  padding: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
  width: calc(100% - 16px);
  box-sizing: border-box;
}

.af-activity-entry__button-container {
  overflow: auto;
}

.af-activity-entry__form button {
  float: right;
  border: 0;
  background-color: #e22345;
  color: white;
  font-size: 1.1em;
  padding: 6px 12px;
  margin-top: 8px;
  cursor: pointer;
}

.af-activity-entry__form button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.af-activity-header {
  margin-top: 16px;
}

.af-activity-header img {
  width: calc(100% - 80px);
}

.af-show-mobile {
  display: none;
}

.af-activity-header {
  position: relative;
}

.af-activity-header .fa {
  color: white;
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 0.9em;
}

@media (max-width: 480px) {
  .af-field-container--ActivityRegistration {
    padding: 8px;
    padding-top: 0;
  }

  .af-activity-header img {
    width: 100%;
    margin: -8px;
    padding: 8px;
  }

  .af-hide-mobile {
    display: none;
  }

  .af-show-mobile {
    display: block;
  }
}

@media (max-width: 480px) {
  .af-multi-string-container {
    display: block;
    width: 100%;
  }

  input.af-multi-string-field {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }

  .af-multi-numeric-container label {
    display: inline-grid !important;
  }

  .af-multi-numeric-input {
    margin-top: 4px;
  }
}

.react-phone-number-input__icon {
  border: none;
  width: 1.4em;
  height: 1em;
  margin-left: 5px;
}

.react-phone-number-input--invalid.af-phoneNumber-field .react-phone-number-input__row {
  border: 1px solid #d30f00;
}

.af-field-container--PhoneNumberField .af-label {
  vertical-align: middle;
}

.af-phoneNumber-field.PhoneInput {
  border: 1px solid #bbb;
  padding: 8px;
  border-radius: 4px;
}

.af-phoneNumber-field .PhoneInputCountry {
  background: #ededed;
  margin: -8px;
  padding-left: 10px;
  padding-right: 6px;
  border-right: 1px solid #bbb;
}

.af-phoneNumber-field.PhoneInput.invalid {
  border-color: #d30f00;
}

.af-phoneNumber-field input {
  border: none;
  outline: none;
  font-size: 15px;
  margin-left: 20px;
}

.af-phoneNumber-field .PhoneInputCountryIcon--border {
  box-shadow: none;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  left: -8px !important;
}

.react-datepicker__time-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.af-message.af-message--error {
  color: #9f3a38;
  background: #fff6f6;
  border: 1px solid #e0b4b4;
  padding: 4px 10px;
  border-radius: 4px;
  max-width: max-content;
}

.af-message.af-message--info {
  color: #166dd1;
  background: #c6effe;
  border: 1px solid #166dd1;
  padding: 4px 10px;
  border-radius: 4px;
  max-width: max-content;
}

.af-slider-field .rc-slider-mark {
  position: relative;
  display: flex;
  top: 0px;
  margin: 10px 0px;
  justify-content: space-between;
  align-items: center;
}

.af-slider-field .rc-slider-mark-text {
  position: relative;
  transform: none !important;
  left: unset !important;
  color: rgba(0, 0, 0, 0.5);
}

/* auto-required star */
.af-field-container.is-required:not(.hide-required) .af-label:after {
  content: '\00a0*';
  color: red;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: calc(100% - 20px);
  height: 18px;
  padding: 10px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 220px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

[data-tooltip] {
  position: relative;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
  z-index: 10;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  /*   font-size: 0.73rem; */
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg, rgba(59, 72, 80, 0.44), rgba(59, 68, 75, 0.44), rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element,
                              the transition effects for the
                              tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* Slide down effect only on mouseenter (NOT on mouseleave) */
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

.swal2-title {
  font-size: 1em !important;
}

.swal2-icon {
  width: 3em !important;
  height: 3em !important;
  margin: 0.25em auto 0.875em !important;
}

.swal2-icon .swal2-icon-content {
  font-size: 2.75em !important;
}
.swal2-icon .swal2-x-mark {
  font-size: 0.6em !important;
}

.u-summary-only {
  display: none;
}

.af-form-container--summary .u-summary-only {
  display: block;
}
