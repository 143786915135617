.af-field-container--AccommodationReservation {
    >.label {
        font-weight: bold;
    }

    &.summary {
        display: flex;
    }

    &.summary .list {
        padding-left: 5.9em;
        display: flex;
        flex-direction: column;
    }
    &.summary * {
        display: table-cell;
        min-width: 175px;
    }

    &.summary>.list {
        padding-left: 1em;

        .accommodation {
            padding: 0 !important;
            border: none;
            margin: 0;
            box-sizing: unset;
            flex-grow: 1;
        }
    }

    >.list {
        .accommodation {
            padding: 8px 12px !important;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            margin-bottom: 8px;
            margin-right: 0px !important;
            flex-grow: 0;
            box-sizing: border-box;

            &__title {
                font-weight: bold;

                .stars {
                    margin-left: 10px;

                    i {
                        color: #fee623;
                        text-shadow: 0 -1px 0 #ddc507, -1px 0 0 #ddc507, 0 1px 0 #ddc507, 1px 0 0 #ddc507 !important;
                    }
                }

            }

            &__level--title {
                font-weight: bold;
                margin: 10px 0;
            }

            &__levels {
                .level {
                    .full {
                        margin-top: 2px;
                        color: #df4441;
                        font-size: 10px;
                    }

                    input {
                        margin-right: 8px;
                        margin-left: 0px;
                    }

                }
            }
        }
    }
}
