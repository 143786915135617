.full-width {
  width: 100%;
}
.awareness-block {
  box-sizing: border-box;
  background: #fff1dd;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 30px;
  &__description {
    flex: 1;
  }
  &__image-wrapper {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & img {
      width: 100%;
    }
  }
}
