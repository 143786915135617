.af-field-container.af-field-container--Basket .af-field {
  padding-left: 0 !important;
}

table.table--simple {
  margin: 12px 0;
  border-collapse: collapse;
  border: 1px solid #DDD;
  table-layout: fixed;
  width: 100%;
}

.table--simple th {
  background: #f0f0f0;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
}

.table--simple tbody>tr, .table--simple tfoot>tr {
  border-top: 1px solid #DDD;
}

.table--simple th,
.table--simple td {
  padding: 8px 12px;
}

.table--simple thead {
  font-size: 11px;
}

.table--simple tbody,
.table--simple tfoot {
  font-size: 14px;
}

.table--simple tr {
  background: #fff;
}

/* .table--simple tr:nth-child(even) {
  background: #efefef;
} */

.table--simple tbody>tr:hover {
  background: #f7f7f7;
}