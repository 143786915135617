.countdown {
    margin-bottom: 30px;
    text-align: center;

    &__summary {
        flex: 1.2;
        background: white;
        padding: 0.7em 1em;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;

        .af-field-container {
            margin-top: 0px;
        }
    }

    &__container {
        display: flex;
        border-width: 1px;
        border-style: solid;
        border-radius: 6px;


        .content {
            flex: 1.8;
            text-align: left;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding: 1em;

            .timeLeft {
                font-size: 1.8em;
                font-weight: bold;
                min-width: 100px;
                position: fixed;
                text-align: center;
                right: 0px;
                top: 0px;
                z-index: 1000;
                color: white;
                border-bottom-left-radius: 8px;
            }

            .text {
                flex: 1;
                font-size: 1em;
            }
        }

    }

    &__back {
        margin-top: 10px !important;
    }
}

@media (max-width: 480px) {
    .countdown {
        &__container {
            display: block;
        }
    }

}