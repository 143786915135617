.af-field-container--ProductsField {
  .af-field {
    text-align: left;
  }

  .af-message--error {
    margin:auto;
  }

  &__unlabeled {
    width: 100%;
  }

  .Products {
    margin-top: 0.7em;

    &__Product {
      margin-bottom: 0.5em;

      > label {
       display: flex;
      }

      input {
        margin-right: 0.5em;
      }
      .name {
        width: 14em;
        display: inline;
      }
      .amount {
        font-weight: bold;
      }

      &.summary {
        .name {
          padding-left: 11.9em;
        }
        .amount {
          font-weight: normal;
        }

        &.total {
          .name {
            font-weight: bold;
          }
          .amount {
            font-weight: bold;
            font-size: 1.2em;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .af-field-container--ProductsField {
    .Products {
      padding-left: 0em;
    }
  }
}
