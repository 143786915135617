.Steps {
  counter-reset: step;
  padding: 0;
  z-index: 10;
  margin: 0;
  text-align: center;

  &--size-small {
    .Step {
      width: 90px;
      font-size: 12px;
      cursor: pointer;

      &.is-disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }

      &:before {
        width: 20px;
        height: 20px;
        line-height: 20px;
      }

      &:after {
        top: 10px;
      }
    }
  }
}

.Step {
  list-style: none;
  display: inline-block;
  // width: 30.33%;
  width: 140px;
  position: relative;
  text-align: center;
  z-index: 1;
  font-size: 16px;
  vertical-align: top;
  // cursor: pointer;

  &__label {
    display: inline;
    color: black;
  }

  &:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
  }

  &:first-child:after {
    content: none;
  }
}
