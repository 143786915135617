.af-quota {
    &--full {
        .af-quota__label{
            color: red;
        }
    }
}

.af-field-container.af-field-container--RadioField {
    .description + .af-quota {
        padding-left: 0;
    }
}