.icon {
  &--error {
    color: #9f3a38;
  }
  &--success {
    color: #21ba45;
  }
}

.af-field-container.af-field-container--TableNumericField {
  margin-bottom: 16px;

  .af-message {
    margin-top: 0;
  }

  .af-field {
    padding-left: 0 !important;

    table {
      width: 100%;
      margin-right: 0;
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: collapse;

      td {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 4px 8px;
      }

      thead {
        .table--label {
          width: 45%;
          border: 0;
        }

        .table--input,
        .table--total {
          text-align: center;
          background: rgba(0, 0, 0, 0.025);
          font-size: 0.9em;
        }

        .table--total {
          width: 55px;
        }
      }

      tbody {
        .table--label {
          font-size: 0.9em;
          background: rgba(0, 0, 0, 0.025);
        }

        .table--input {
          padding: 0;
        }
        .table--total {
          vertical-align: middle;
          text-align: right;
          font-size: 0.8em;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
            "Helvetica Neue", sans-serif;

          i.fa {
            margin-top: 2px;
            float: left;
          }
        }
      }

      .rc-input-number {
        border: 0;
        width: 100%;

        &-focused {
          box-shadow: none;
        }
      }

      .rc-input-number:hover .rc-input-number-handler-up,
      .rc-input-number:hover .rc-input-number-handler-wrap {
        border-color: #d9d9d9;
      }
    }
  }
}
