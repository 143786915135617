.af-form-container--summary {
  .af-field-container--CheckboxField {
    & > .af-field {
      justify-content: flex-start !important;
    }
  }
}

.af-field-container.af-field-container--CheckboxField {
  cursor: pointer;

  > .af-field {
    width: 175px;
    display: flex;
    margin-right: 10px;
    justify-content: flex-end;
    text-align: left;
    position: relative;
  }

  > .af-field input {
    position: absolute;
    opacity: 0;
  }

  &--summary,
  &--button {
    padding: 8px 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 0px !important;
    width: 100%;
    box-sizing: border-box;
    display: table;

    > .af-field {
      padding-left: 0 !important;
      margin-top: 0px;
      margin-right: 0px;
      width: 28px;
      max-width: 28px;
      height: 20px;
      vertical-align: top;
      display: table-cell;
    }

    > .af-label {
      margin-left: 0px !important;
      display: table-cell;
      vertical-align: middle;
    }
  }
  &--summary {
    border: none;
    > .af-label {
      font-weight: normal;
    }
  }
}

input.af-single-checkbox-field ~ .checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background: #ddd;
}

input.af-checkbox-field:hover ~ .checkmark {
  background-color: #9faab7;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

input.af-single-checkbox-field:checked ~ .checkmark {
  background-color: #ed1c24;
}

input.af-single-checkbox-field:checked ~ .checkmark:after {
  display: block;
}
