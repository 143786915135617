.flight-field-container {
  h3 {
    margin-bottom: 8px;
  }

  .description {
    margin: 8px 0;
    font-size: 0.9em;
    opacity: 0.9;
  }

  & .af-string-field {
    max-width: 200px !important;
  }

  & .incorrect-hint {
    color: lightcoral;
    font-size: 10px;
  }

  & .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0px;
  }

  & .add-connection-button {
    color: var(--ac-color-primary) !important;
    background: transparent !important;
    font-size: 14px !important;
    border: 0;
    margin: 8px;
    cursor: pointer;
  }

  & .search-flight {
    &--spaced {
      padding: 8px;
    }
    & .buttons-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      gap: 12px;

      & button {
        margin: 0 !important;
      }
    }
  }

  & .search-error {
    color: lightcoral;
    font-size: 14px;
  }

  & .flight-card {
    margin-bottom: 12px;
  }
}
