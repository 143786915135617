.af-tabs {
  &__header {
    padding-top: 8px;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }

  &__content {
    display: none;

    &--active {
      display: block;
    }

    &--disabled {
      opacity: 0.35;
      pointer-events: none;
    }
  }
}

.af-tab {
  border-bottom: 2px solid transparent;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: -2px;
  cursor: pointer;

  &--active {
    border-color: #1b1c1d;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.tabs__summary {
  & .tab {
    h1 {
      border-bottom: 1px solid;
    }
    .af-field-container--CheckboxField .af-field {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 480px) {
  // .af-tabs__header {
  //   overflow: hidden;
  //   white-space: nowrap;
  //   max-width: calc(100%);
  // }

  .af-tab {
    font-size: 14px;
    padding: 6px 8px;
  }
}
