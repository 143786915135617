.af-field-container.af-field-container--PaymentCB .af-field {
  padding-left: 0 !important;
}

.af-field-container.af-field-container--PaymentCB .af-label {
  vertical-align: middle;
}

.af-payment .af-field-container .af-label {
  min-width: 125px;
}

.StripeElement {
  padding: 8px;
  border: 1px solid #DDD;
  border-radius: 4px;
  background-color: #F7F7F7;
}