select.af-select-field {
  padding: 5px 40px 5px 5px;
  max-width: 260px;
}

.af-field-container.af-field-container--SelectField {
  position: relative;

  > .af-label {
    vertical-align: middle;
  }

  .af-field {
    position: relative;
  }

  select {
    height: 39px;
    font-size: 16px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    display: block;
    max-width: initial;
    appearance: none;
    position: relative;

    &:hover {
      border-color: hsl(0, 0%, 70%);
    }

    &:focus {
      outline: solid 2px #2684ff;
    }
  }
}

.Select {
  &__IndicatorContainer {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    align-items: center;
    display: flex;
  }

  &__IndicatorBar {
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
  }

  &__IndicatorArrow {
    display: flex;
    padding: 8px;
  }
}
