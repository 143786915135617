.af-field-container--WorkshopRegistration {

  .WorkshopRegistrationV2__Date {
    h4 {
      margin-bottom: 0.25em;
      margin-top: 1em;
    }

    &:first-child h4 {
      margin-top: 0;
    }
  }


  > .label {
    padding: 2px 0;
    font-weight: bold;
  }

  > .description {
  }

  > .list:not(.list--columns-2) {
    padding-left: 11.9em;
  }

  > .list--columns-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.list--showDateOnly {
      .af-quota {
        padding-left: 0;
      }
    }

    .session {
      width: 50%;
    }
  }

  .session {
    display: table;
    width: 100%;
    padding: 0;
    margin: 4px 0;
    font-weight: 0.9em;

    > * {
      display: table-cell;
    }

    .date,
    .description {
      font-weight: normal;
      font-size: 0.9em;
      line-height: 1.5em;
      opacity: 0.85;
      white-space: pre-line;
    }

    .category-tag {
      margin-left: 2em;
      background: #d8d8d8;
      padding: 0.3em 0.7em;
      border-radius: 3em;
      font-size: 0.85em;
    }

    .category {
      background: #d8d8d8;
      border-radius: 3em;
      font-size: 0.7em;
      max-width: max-content;
      line-height: 1;
      padding: 0.5em 0.7em;
      margin-bottom: 0.4em;
    }
  }
}

@media (max-width: 480px) {
  .af-field-container--WorkshopRegistration {
    > .list {
      padding-left: 0em !important;
    }

    > .list--columns-2 {
      .session {
        width: 100%;
      }
    }
  }
}
