@mixin is-header($level, $fontSize, $margin) {
  &.is-h#{$level} {
    display: block;
    font-size: $fontSize;
    margin-block-start: $margin;
    margin-block-end: $margin;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
}

.af-label {
  @include is-header(1, 2em, 0.67em);
  @include is-header(2, 1.5em, 0.83em);
  @include is-header(3, 1.17em, 1em);
  @include is-header(4, 1em, 1.33em);
  @include is-header(5, 0.83em, 1.67em);
  @include is-header(6, 0.67em, 2.33em);
  &.is-hidden {
    display: none;
  }
}
