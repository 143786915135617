.af-field-container--RadioField--location.af-field-container--RadioField--button {

    .af-radio-label {
        line-height: 1.2;
        min-height: 60px;
    }

    .text--workshops--room-left {
        display: block;
        padding-left: 0;
    }
}