.af-field-container--CarbonFootprintTransportField {
    h3 {
        color: var(--ac-color-primary);
        margin-bottom: 8px;
    }

    h4 {
        margin-top: 1em;
        margin-bottom: 0.5em;
    }

    .description {
        margin: 8px 0;
        font-size: 0.9em;
        opacity: 0.9;
    }
}
