.flight-card {
  margin-top: 1em !important;
  .ui.segment {
    &.segment--header {
      padding-bottom: 0px;
      .ui.header {
        font-weight: inherit;
        font-size: inherit;
        margin: 0px;
        p,
        h3 {
          margin: 0px;
        }
      }
    }
    &.segment--content {
      border-top: none;
    }
  }
  &__header {
    padding: 1em;
    border-radius: 10px 10px 0px 0px;

    h3.ui.header {
      color: white !important;
      margin-bottom: 4px;

      &.title {
        p {
          margin-bottom: 0px;
        }
      }

      &.subtitle {
        margin-top: 0px;
        margin-bottom: 0.6em;
        font-weight: normal;
      }
    }

    .flight-number {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .divider {
        flex: 1;
        height: 1px;
        margin: 0px 1em;
        background-color: #ffffff;
      }

      .number {
        font-weight: bold;
      }
    }
  }

  &__steps {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #a8a5a5;
    border-top: none;
  }
  &__date {
    margin: 0 16px;
    font-size: 12px;
    padding-top: 12px;
    font-style: italic;
  }

  &__step {
    padding: 1em 0px;
    margin: 0px 1em;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #d5d6d8;
    }

    .circle {
      height: 1em;
      width: 1em;
      border-radius: 50%;
      border: 3px solid #a8a5a5;
      min-width: 1em;
    }
    .stopovers {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 1em;

      .divider {
        width: 2px;
        flex: 1;
        border-left: 2px dashed #a8a5a5;
      }
    }
    .time {
      margin-right: 1em;
      font-weight: bold;
    }
    .airport {
      font-weight: bold;
      color: black;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__infos {
        font-size: 14px !important;
        color: gray !important;
        font-weight: normal !important;
      }
    }
    .departure-and-duration {
      display: flex;
      .departure {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .duration {
        min-height: 1.5em;
        font-size: 0.9em;
        color: #a8a5a5;
        padding: 0.2em 0px;
        margin: 8px 0;
      }
    }
    .arrival {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .time {
        margin: 0px 1em;
      }
    }
  }

  & .card-actions {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    & .fa.fa-fw {
      cursor: pointer;
      background-color: transparent;
      transition: background 250ms;
      padding: 8px;
      border-radius: 30px;

      &:hover {
        background: lightgray;
      }
    }
    & .edit-icon {
      color: grey !important;
    }

    & .delete-icon {
      color: lightcoral !important;
    }
  }

  & .small-input {
    max-width: 50px;
  }

  & .disclaimer {
    padding: 8px;
    color: gray;
    font-style: italic;
    font-size: 14px;

    & .fa-warning {
      color: orange;
      margin-right: 4px;
    }
  }
}
