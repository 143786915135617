.af-field-container--PhoneNumberField.is-vertical .af-field {
  margin-top: 8px;
}

.af-phoneNumber-field {
  &.hideCountrySelector {
    &.PhoneInput {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
      padding: 0;
    }

    .PhoneInputCountry {
      display: none;
    }
    > input {
      margin-left: 0;
    }
  }
}

.af-field-container--PhoneNumberField {
  .PhoneInput__confirmation {
    .af-form-button {
      margin-top: 0;
      margin-right: 8px;
      position: relative;

      @media (max-width: 480px) {
        display: block;
      }

      &__icon__container {
        position: absolute;
        left: 0;
        right: 0;
        top: 6px;
        bottom: 6px;
        text-align: center;
      }
    }

    .af-string-field {
      display: inline-block;
      width: 100px;
      padding: 8px;
    }

    .icon--success {
      color: green;
    }
    .icon--error {
      color: red;
    }
  }
}
