// From https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch

:root {
    --af-switch-size: 18px;
    --af-switch-width: calc(var(--af-switch-size) * 2.5);
    --af-switch-outer-size: calc(var(--af-switch-size) + 8px);
}

.af-switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
}

.af-switch {
    position: relative;
    display: inline-block;
    width: var(--af-switch-width);
    height: var(--af-switch-outer-size);
    margin-right: 8px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + .af-switch-slider {
        background-color: var(--ac-color-primary);
    }

    input:checked + .af-switch-slider:before {
        transform: translateX(var(--af-switch-size));
    }

    &-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.25s;

        &:before {
            position: absolute;
            content: "";
            height: var(--af-switch-size);
            width: var(--af-switch-size);
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: 0.25s;
        }

        /* Rounded sliders */
        &.round {
            border-radius: var(--af-switch-outer-size);
        }

        &.round:before {
            border-radius: 50%;
        }
    }
}
