.af-field-container.af-field-container--MultiSelectField,
.af-field-container.af-field-container--CountrySelectField {
    .af-label {
        vertical-align: middle;
    }
}

.af-multi-select-container {
    position: relative;
}
